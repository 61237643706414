import React from "react"
import { useSfx } from "../hooks/use-sfx.js"
import emeritus from "../images/emeritus.png"
import wmg from "../images/wmg.png"
import seatmate from "../images/seatmate.png"
import okjux from "../images/okjux.png"
import illumehealth from "../images/illumehealth.png"
import retroally from "../images/retroally.png"
import rootstrap from "../images/rootstrap.png"
import wyeworks from "../images/wyeworks.png"

import samsung from "../images/samsung.png";
import miniclip from "../images/miniclip.png";
import multiplan from "../images/multiplan.png";
import net from "../images/net.png";
import mercedes from "../images/mercedes.png";
import kawasaki from "../images/kawasaki.png";
import nestle from "../images/nestle.png";
import fleury from "../images/fleury.png";
import yallaplay from "../images/yallaplay.png";

import styles from "../styles/projects.module.css"

export function Projects() {
  const { playClick, playPop } = useSfx()

  return (
    <div className={styles.logosContainer}>
      <h2 className={styles.mainTitle}>
        <i className="nes-logo"></i>
        André's History
        <i className="nes-logo"></i>
      </h2>
      <div id={styles.companiesSection} className={styles.logoSection}>
        <h3 id={styles.companiesTitle} className={styles.logoGroupTitle}>
          ""
        </h3>
        <div className={styles.logoGroup}>
          <a
            href="https://www.samsung.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Samsung"
              loading="lazy"
              src={samsung}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
          <a href="https://miniclip.com/" target="_blank" rel="noreferrer">
            <img
              alt="Miniclip"
              loading="lazy"
              src={miniclip}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
          <a href="https://yallaplay.com/" target="_blank" rel="noreferrer">
            <img
              alt="YallPlay"
              loading="lazy"
              src={yallaplay}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
        </div>
      </div>
      <div id={styles.startupsSection} className={styles.logoSection}>
        <h3 id={styles.startupsTitle} className={styles.logoGroupTitle}>
          
        </h3>
        <div className={styles.logoGroup}>
          <a href="http://net.com.br/" target="_blank" rel="noreferrer">
            <img
              style={{ width: "55px" }}
              alt="NET"
              loading="lazy"
              src={net}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
          <a href="https://kawasaki.com/" target="_blank" rel="noreferrer">
            <img
              alt="Kawasaki"
              loading="lazy"
              src={kawasaki}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
          <a
            href="https://www.mercedes-benz.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: "120px" }}
              alt="Mercedes Benz"
              loading="lazy"
              src={mercedes}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
          <a
            href="https://nestle.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: "150px" }}
              alt="Nestlé"
              loading="lazy"
              src={nestle}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
        </div>
      </div>
      <div className={styles.logoSection}>
        <h3 id={styles.factoriesTitle} className={styles.logoGroupTitle}>
          ""
        </h3>
        <div id={styles.factoriesLogos} className={styles.logoGroup}>
          <a href="https://www.multiplan.com/" target="_blank" rel="noreferrer">
            <img
              alt="Multiplan"
              loading="lazy"
              src={multiplan}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
          <a href="https://www.fleury.com/" target="_blank" rel="noreferrer">
            <img
              alt="Fleury"
              loading="lazy"
              src={fleury}
              onMouseOver={playPop}
              onClick={playClick}
            ></img>
          </a>
        </div>
      </div>
    </div>
  )
}
