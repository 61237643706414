import React, { useState } from "react"
import { useSfx } from "../hooks/use-sfx.js"
import styles from "../styles/skills.module.css"

const skills = {
  frontend: [
    { name: "Unity", favorite: true },
    { name: "C#", favorite: true },
    { name: "Javascript", favorite: false },
    { name: "npm & Yarn", favorite: false },
    { name: "React", favorite: true },
    { name: "Redux", favorite: false },
    { name: "Java", favorite: false },
    { name: "C++", favorite: false },
    { name: "TypeScript", favorite: false },
    { name: "Action Script 3", favorite: false },
  ],
  backend: [
    { name: "MongoDB", favorite: false },
    { name: "Node/Express", favorite: false },
    { name: "REST API", favorite: true },
    { name: ".NETCORE", favorite: true },
  ],
  languages: [
    { name: "English", favorite: false },
    { name: "Portuguese", favorite: false },
  ],
  other: [
    { name: "Agile (Scrum)", favorite: false },
    { name: "Git", favorite: false },
    { name: "Jira", favorite: true },
    { name: "Delta DNA", favorite: true },
    { name: "Embrace", favorite: false },
    { name: "Firebase", favorite: false },
    { name: "Testing", favorite: false },
    { name: "Admob", favorite: false },
    { name: "IronSource", favorite: true },
    { name: "Audience Network", favorite: false },
  ],
}

export function Skills() {
  const [selectedSkill, setSelectedSkill] = useState(Object.keys(skills)[0])
  const { playClick, playPop } = useSfx()

  const handleChange = event => {
    playClick()
    setSelectedSkill(event.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainTitleContainer}>
        <i className={`${styles.titleIcon} nes-icon trophy is-medium`}></i>
        <h2 className={styles.mainTitle}>André's</h2>
        <div className={`${styles.select} nes-select`}>
          <select
            required
            value={selectedSkill}
            onMouseOver={playPop}
            onChange={handleChange}
          >
            {Object.keys(skills).map(skillOption => {
              return (
                <option key={skillOption} value={skillOption}>
                  {skillOption.charAt(0).toUpperCase() + skillOption.slice(1)}
                </option>
              )
            })}
          </select>
        </div>
        <h2 className={styles.mainTitle}>Skills</h2>
        <i className={`${styles.titleIcon} nes-icon trophy is-medium`}></i>
      </div>

      <div className={styles.skillsContainer}>
        {skills[selectedSkill].map(skill => {
          return (
            <span key={skill.name} className={styles.badgeContainer}>
              {skill.favorite && (
                <a className={`${styles.favorite} nes-badge is-icon`}>
                  <span className="is-warning">
                    <i className="nes-icon star is-small"></i>
                  </span>
                  <span></span>
                </a>
              )}
              <a className={`${styles.badge} nes-badge`}>
                <span className="is-primary">{skill.name}</span>
              </a>
            </span>
          )
        })}
      </div>
    </div>
  )
}
